/* eslint @typescript-eslint/camelcase: "off" */

import Fuse from "fuse.js";

export interface SectionInterface {
  id: string;
  href: string;
  text: string;
  level: number;
  parent: null | string;
}

export type MiniSectionInterface = Pick<SectionInterface, "id" | "text">;

export interface SectionsInterface {
  [id: string]: SectionInterface;
}

export const SECTIONS: SectionsInterface = {
  "news-lv0": {
    id: "news-lv0",
    href: "/news/",
    text: "News",
    level: 0,
    parent: null
  },
  "politics-lv0": {
    id: "politics-lv0",
    href: "/politics/",
    text: "Politics",
    level: 0,
    parent: null
  },
  "sport-lv0": {
    id: "sport-lv0",
    href: "/sport/",
    text: "Sport",
    level: 0,
    parent: null
  },
  "business-lv0": {
    id: "business-lv0",
    href: "/business/",
    text: "Business",
    level: 0,
    parent: null
  },
  "money-lv0": {
    id: "money-lv0",
    href: "/money/",
    text: "Money",
    level: 0,
    parent: null
  },
  "opinion-lv0": {
    id: "opinion-lv0",
    href: "/opinion/",
    text: "Opinion",
    level: 0,
    parent: null
  },
  "tech-lv0": {
    id: "tech-lv0",
    href: "/technology/",
    text: "Tech",
    level: 0,
    parent: null
  },
  "lifestyle-lv0": {
    id: "lifestyle-lv0",
    href: "/lifestyle/",
    text: "Life & Style",
    level: 0,
    parent: null
  },
  "travel-lv0": {
    id: "travel-lv0",
    href: "/travel/",
    text: "Travel",
    level: 0,
    parent: null
  },
  "culture-lv0": {
    id: "culture-lv0",
    href: "/culture/",
    text: "Culture",
    level: 0,
    parent: null
  },
  "uknews-lv1-news-lv0": {
    id: "uknews-lv1-news-lv0",
    href: "/news/uk/",
    text: "UK news",
    level: 1,
    parent: "news-lv0"
  },
  "worldnews-lv1-news-lv0": {
    id: "worldnews-lv1-news-lv0",
    href: "/news/world/",
    text: "World news",
    level: 1,
    parent: "news-lv0"
  },
  "usa-lv2-worldnews-lv1-news-lv0": {
    id: "usa-lv2-worldnews-lv1-news-lv0",
    href: "/usa/",
    text: "USA",
    level: 2,
    parent: "worldnews-lv1-news-lv0"
  },
  "europe-lv2-worldnews-lv1-news-lv0": {
    id: "europe-lv2-worldnews-lv1-news-lv0",
    href: "/europe/",
    text: "Europe",
    level: 2,
    parent: "worldnews-lv1-news-lv0"
  },
  "middleeast-lv2-worldnews-lv1-news-lv0": {
    id: "middleeast-lv2-worldnews-lv1-news-lv0",
    href: "/middle-east/",
    text: "Middle East",
    level: 2,
    parent: "worldnews-lv1-news-lv0"
  },
  "asia-lv2-worldnews-lv1-news-lv0": {
    id: "asia-lv2-worldnews-lv1-news-lv0",
    href: "/asia/",
    text: "Asia",
    level: 2,
    parent: "worldnews-lv1-news-lv0"
  },
  "australasia-lv2-worldnews-lv1-news-lv0": {
    id: "australasia-lv2-worldnews-lv1-news-lv0",
    href: "/australasia/",
    text: "Australasia",
    level: 2,
    parent: "worldnews-lv1-news-lv0"
  },
  "africa-lv2-worldnews-lv1-news-lv0": {
    id: "africa-lv2-worldnews-lv1-news-lv0",
    href: "/africa/",
    text: "Africa",
    level: 2,
    parent: "worldnews-lv1-news-lv0"
  },
  "latinamerica-lv2-worldnews-lv1-news-lv0": {
    id: "latinamerica-lv2-worldnews-lv1-news-lv0",
    href: "/latin-america/",
    text: "Latin America",
    level: 2,
    parent: "worldnews-lv1-news-lv0"
  },
  "royals-lv1-news-lv0": {
    id: "royals-lv1-news-lv0",
    href: "/royal-family/",
    text: "Royals",
    level: 1,
    parent: "news-lv0"
  },
  "thequeen-lv2-royals-lv1-news-lv0": {
    id: "thequeen-lv2-royals-lv1-news-lv0",
    href: "/queen-elizabeth-ii/",
    text: "The Queen",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "princephilip-lv2-royals-lv1-news-lv0": {
    id: "princephilip-lv2-royals-lv1-news-lv0",
    href: "/prince-philip/",
    text: "Prince Philip",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "princecharles-lv2-royals-lv1-news-lv0": {
    id: "princecharles-lv2-royals-lv1-news-lv0",
    href: "/prince-charles/",
    text: "Prince Charles",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "duchessofcornwall-lv2-royals-lv1-news-lv0": {
    id: "duchessofcornwall-lv2-royals-lv1-news-lv0",
    href: "/camilla-duchess-of-cornwall/",
    text: "Duchess of Cornwall",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "princewilliam-lv2-royals-lv1-news-lv0": {
    id: "princewilliam-lv2-royals-lv1-news-lv0",
    href: "/prince-william/",
    text: "Prince William",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "duchessofcambridge-lv2-royals-lv1-news-lv0": {
    id: "duchessofcambridge-lv2-royals-lv1-news-lv0",
    href: "/duchess-of-cambridge/",
    text: "Duchess of Cambridge",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "princeharry-lv2-royals-lv1-news-lv0": {
    id: "princeharry-lv2-royals-lv1-news-lv0",
    href: "/prince-harry/",
    text: "Prince Harry",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "duchessofsussex-lv2-royals-lv1-news-lv0": {
    id: "duchessofsussex-lv2-royals-lv1-news-lv0",
    href: "/duchess-of-sussex/",
    text: "Duchess of Sussex",
    level: 2,
    parent: "royals-lv1-news-lv0"
  },
  "health-lv1-news-lv0": {
    id: "health-lv1-news-lv0",
    href: "/health/",
    text: "Health",
    level: 1,
    parent: "news-lv0"
  },
  "defence-lv1-news-lv0": {
    id: "defence-lv1-news-lv0",
    href: "/news/defence/",
    text: "Defence",
    level: 1,
    parent: "news-lv0"
  },
  "science-lv1-news-lv0": {
    id: "science-lv1-news-lv0",
    href: "/science/",
    text: "Science",
    level: 1,
    parent: "news-lv0"
  },
  "education-lv1-news-lv0": {
    id: "education-lv1-news-lv0",
    href: "/education/",
    text: "Education",
    level: 1,
    parent: "news-lv0"
  },
  "primary-lv2-education-lv1-news-lv0": {
    id: "primary-lv2-education-lv1-news-lv0",
    href: "/primary-education/",
    text: "Primary",
    level: 2,
    parent: "education-lv1-news-lv0"
  },
  "secondary-lv2-education-lv1-news-lv0": {
    id: "secondary-lv2-education-lv1-news-lv0",
    href: "/secondary-education/",
    text: "Secondary",
    level: 2,
    parent: "education-lv1-news-lv0"
  },
  "university-lv2-education-lv1-news-lv0": {
    id: "university-lv2-education-lv1-news-lv0",
    href: "/university-education/",
    text: "University",
    level: 2,
    parent: "education-lv1-news-lv0"
  },
  "leaguetables-lv2-education-lv1-news-lv0": {
    id: "leaguetables-lv2-education-lv1-news-lv0",
    href: "/education/league-tables/",
    text: "League Tables",
    level: 2,
    parent: "education-lv1-news-lv0"
  },
  "clearing-lv2-education-lv1-news-lv0": {
    id: "clearing-lv2-education-lv1-news-lv0",
    href: "/education/clearing/",
    text: "Clearing",
    level: 2,
    parent: "education-lv1-news-lv0"
  },
  "investigations-lv1-news-lv0": {
    id: "investigations-lv1-news-lv0",
    href: "/news/investigations/",
    text: "Investigations",
    level: 1,
    parent: "news-lv0"
  },
  "signalsnetwork-lv2-investigations-lv1-news-lv0": {
    id: "signalsnetwork-lv2-investigations-lv1-news-lv0",
    href: "https://www.telegraph.co.uk/bigdatacall/",
    text: "Signals Network",
    level: 2,
    parent: "investigations-lv1-news-lv0"
  },
  "contactus-lv2-investigations-lv1-news-lv0": {
    id: "contactus-lv2-investigations-lv1-news-lv0",
    href: "/news/investigations/contact-us/",
    text: "Contact us",
    level: 2,
    parent: "investigations-lv1-news-lv0"
  },
  "brexit-lv1-politics-lv0": {
    id: "brexit-lv1-politics-lv0",
    href: "/brexit/",
    text: "Brexit",
    level: 1,
    parent: "politics-lv0"
  },
  "conservatives-lv1-politics-lv0": {
    id: "conservatives-lv1-politics-lv0",
    href: "/conservative-party/",
    text: "Conservatives",
    level: 1,
    parent: "politics-lv0"
  },
  "labour-lv1-politics-lv0": {
    id: "labour-lv1-politics-lv0",
    href: "/labour-party/",
    text: "Labour",
    level: 1,
    parent: "politics-lv0"
  },
  "libdems-lv1-politics-lv0": {
    id: "libdems-lv1-politics-lv0",
    href: "/liberal-democrats/",
    text: "Lib Dems",
    level: 1,
    parent: "politics-lv0"
  },
  "snp-lv1-politics-lv0": {
    id: "snp-lv1-politics-lv0",
    href: "/scottish-national-party/",
    text: "SNP",
    level: 1,
    parent: "politics-lv0"
  },
  "uspolitics-lv1-politics-lv0": {
    id: "uspolitics-lv1-politics-lv0",
    href: "/us-politics/",
    text: "US politics",
    level: 1,
    parent: "politics-lv0"
  },
  "football-lv1-sport-lv0": {
    id: "football-lv1-sport-lv0",
    href: "/football/",
    text: "Football",
    level: 1,
    parent: "sport-lv0"
  },
  "livescores-lv2-football-lv1-sport-lv0": {
    id: "livescores-lv2-football-lv1-sport-lv0",
    href: "/football/live-scores/",
    text: "Live Scores",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "fixturesresults-lv2-football-lv1-sport-lv0": {
    id: "fixturesresults-lv2-football-lv1-sport-lv0",
    href: "/football/fixtures-results/",
    text: "Fixtures & Results",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "tables-lv2-football-lv1-sport-lv0": {
    id: "tables-lv2-football-lv1-sport-lv0",
    href: "/football/all-tables/",
    text: "Tables",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "premierleague-lv2-football-lv1-sport-lv0": {
    id: "premierleague-lv2-football-lv1-sport-lv0",
    href: "/premier-league/",
    text: "Premier League",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "championsleague-lv2-football-lv1-sport-lv0": {
    id: "championsleague-lv2-football-lv1-sport-lv0",
    href: "/champions-league/",
    text: "Champions League",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "facup-lv2-football-lv1-sport-lv0": {
    id: "facup-lv2-football-lv1-sport-lv0",
    href: "/fa-cup/",
    text: "FA Cup",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "leaguecup-lv2-football-lv1-sport-lv0": {
    id: "leaguecup-lv2-football-lv1-sport-lv0",
    href: "/league-cup/",
    text: "League Cup",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "europaleague-lv2-football-lv1-sport-lv0": {
    id: "europaleague-lv2-football-lv1-sport-lv0",
    href: "/europa-league/",
    text: "Europa League",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "transfers-lv2-football-lv1-sport-lv0": {
    id: "transfers-lv2-football-lv1-sport-lv0",
    href: "/football-transfers/",
    text: "Transfers",
    level: 2,
    parent: "football-lv1-sport-lv0"
  },
  "rugbyunion-lv1-sport-lv0": {
    id: "rugbyunion-lv1-sport-lv0",
    href: "/rugby-union/",
    text: "Rugby Union",
    level: 1,
    parent: "sport-lv0"
  },
  "fixturesresults-lv2-rugbyunion-lv1-sport-lv0": {
    id: "fixturesresults-lv2-rugbyunion-lv1-sport-lv0",
    href: "/rugby-union/fixtures-results/",
    text: "Fixtures & Results",
    level: 2,
    parent: "rugbyunion-lv1-sport-lv0"
  },
  "tables-lv2-rugbyunion-lv1-sport-lv0": {
    id: "tables-lv2-rugbyunion-lv1-sport-lv0",
    href: "/rugby-union/all-tables/",
    text: "Tables",
    level: 2,
    parent: "rugbyunion-lv1-sport-lv0"
  },
  "premiership-lv2-rugbyunion-lv1-sport-lv0": {
    id: "premiership-lv2-rugbyunion-lv1-sport-lv0",
    href: "/gallagher-premiership/",
    text: "Premiership",
    level: 2,
    parent: "rugbyunion-lv1-sport-lv0"
  },
  "cricket-lv1-sport-lv0": {
    id: "cricket-lv1-sport-lv0",
    href: "/cricket/",
    text: "Cricket",
    level: 1,
    parent: "sport-lv0"
  },
  "fixturesresults-lv2-cricket-lv1-sport-lv0": {
    id: "fixturesresults-lv2-cricket-lv1-sport-lv0",
    href: "/cricket/fixtures-and-results/",
    text: "Fixtures & Results",
    level: 2,
    parent: "cricket-lv1-sport-lv0"
  },
  "tables-lv2-cricket-lv1-sport-lv0": {
    id: "tables-lv2-cricket-lv1-sport-lv0",
    href: "/cricket/standings/",
    text: "Tables",
    level: 2,
    parent: "cricket-lv1-sport-lv0"
  },
  "counties-lv2-cricket-lv1-sport-lv0": {
    id: "counties-lv2-cricket-lv1-sport-lv0",
    href: "/county-championship/",
    text: "Counties",
    level: 2,
    parent: "cricket-lv1-sport-lv0"
  },
  "international-lv2-cricket-lv1-sport-lv0": {
    id: "international-lv2-cricket-lv1-sport-lv0",
    href: "/international/",
    text: "International",
    level: 2,
    parent: "cricket-lv1-sport-lv0"
  },
  "formula1-lv1-sport-lv0": {
    id: "formula1-lv1-sport-lv0",
    href: "/formula-1/",
    text: "Formula 1",
    level: 1,
    parent: "sport-lv0"
  },
  "racecalendar-lv2-formula1-lv1-sport-lv0": {
    id: "racecalendar-lv2-formula1-lv1-sport-lv0",
    href: "/formula-1/calendar/",
    text: "Race calendar",
    level: 2,
    parent: "formula1-lv1-sport-lv0"
  },
  "standings-lv2-formula1-lv1-sport-lv0": {
    id: "standings-lv2-formula1-lv1-sport-lv0",
    href: "/formula-1/standings/",
    text: "Standings",
    level: 2,
    parent: "formula1-lv1-sport-lv0"
  },
  "womenssport-lv1-sport-lv0": {
    id: "womenssport-lv1-sport-lv0",
    href: "/womens-sport/",
    text: "Women's Sport",
    level: 1,
    parent: "sport-lv0"
  },
  "cycling-lv1-sport-lv0": {
    id: "cycling-lv1-sport-lv0",
    href: "/cycling/",
    text: "Cycling",
    level: 1,
    parent: "sport-lv0"
  },
  "latestresults-lv2-cycling-lv1-sport-lv0": {
    id: "latestresults-lv2-cycling-lv1-sport-lv0",
    href: "/cycling-results/",
    text: "Latest results",
    level: 2,
    parent: "cycling-lv1-sport-lv0"
  },
  "tourdefrance-lv2-cycling-lv1-sport-lv0": {
    id: "tourdefrance-lv2-cycling-lv1-sport-lv0",
    href: "/tour-de-france/",
    text: "Tour de France",
    level: 2,
    parent: "cycling-lv1-sport-lv0"
  },
  "giroditalia-lv2-cycling-lv1-sport-lv0": {
    id: "giroditalia-lv2-cycling-lv1-sport-lv0",
    href: "/giro-ditalia/",
    text: "Giro d'Italia",
    level: 2,
    parent: "cycling-lv1-sport-lv0"
  },
  "vueltaaespana-lv2-cycling-lv1-sport-lv0": {
    id: "vueltaaespana-lv2-cycling-lv1-sport-lv0",
    href: "/vuelta-a-espana/",
    text: "Vuelta a Espana",
    level: 2,
    parent: "cycling-lv1-sport-lv0"
  },
  "tennis-lv1-sport-lv0": {
    id: "tennis-lv1-sport-lv0",
    href: "/tennis/",
    text: "Tennis",
    level: 1,
    parent: "sport-lv0"
  },
  "calendar-lv2-tennis-lv1-sport-lv0": {
    id: "calendar-lv2-tennis-lv1-sport-lv0",
    href: "/tennis/fixtures/",
    text: "Calendar",
    level: 2,
    parent: "tennis-lv1-sport-lv0"
  },
  "rankings-lv2-tennis-lv1-sport-lv0": {
    id: "rankings-lv2-tennis-lv1-sport-lv0",
    href: "/tennis/atp-and-wta-rankings/",
    text: "Rankings",
    level: 2,
    parent: "tennis-lv1-sport-lv0"
  },
  "wimbledon-lv2-tennis-lv1-sport-lv0": {
    id: "wimbledon-lv2-tennis-lv1-sport-lv0",
    href: "/wimbledon-tennis/",
    text: "Wimbledon",
    level: 2,
    parent: "tennis-lv1-sport-lv0"
  },
  "daviscup-lv2-tennis-lv1-sport-lv0": {
    id: "daviscup-lv2-tennis-lv1-sport-lv0",
    href: "/davis-cup/",
    text: "Davis Cup",
    level: 2,
    parent: "tennis-lv1-sport-lv0"
  },
  "australianopen-lv2-tennis-lv1-sport-lv0": {
    id: "australianopen-lv2-tennis-lv1-sport-lv0",
    href: "/australian-open/",
    text: "Australian Open",
    level: 2,
    parent: "tennis-lv1-sport-lv0"
  },
  "frenchopen-lv2-tennis-lv1-sport-lv0": {
    id: "frenchopen-lv2-tennis-lv1-sport-lv0",
    href: "/french-open/",
    text: "French Open",
    level: 2,
    parent: "tennis-lv1-sport-lv0"
  },
  "usopen-lv2-tennis-lv1-sport-lv0": {
    id: "usopen-lv2-tennis-lv1-sport-lv0",
    href: "/us-open-tennis/",
    text: "US Open",
    level: 2,
    parent: "tennis-lv1-sport-lv0"
  },
  "golf-lv1-sport-lv0": {
    id: "golf-lv1-sport-lv0",
    href: "/golf/",
    text: "Golf",
    level: 1,
    parent: "sport-lv0"
  },
  "rydercup-lv2-golf-lv1-sport-lv0": {
    id: "rydercup-lv2-golf-lv1-sport-lv0",
    href: "/ryder-cup/",
    text: "Ryder Cup",
    level: 2,
    parent: "golf-lv1-sport-lv0"
  },
  "theopen-lv2-golf-lv1-sport-lv0": {
    id: "theopen-lv2-golf-lv1-sport-lv0",
    href: "/the-open/",
    text: "The Open",
    level: 2,
    parent: "golf-lv1-sport-lv0"
  },
  "themasters-lv2-golf-lv1-sport-lv0": {
    id: "themasters-lv2-golf-lv1-sport-lv0",
    href: "/the-masters/",
    text: "The Masters",
    level: 2,
    parent: "golf-lv1-sport-lv0"
  },
  "boxing-lv1-sport-lv0": {
    id: "boxing-lv1-sport-lv0",
    href: "/boxing/",
    text: "Boxing",
    level: 1,
    parent: "sport-lv0"
  },
  "racing-lv1-sport-lv0": {
    id: "racing-lv1-sport-lv0",
    href: "/racing/",
    text: "Racing",
    level: 1,
    parent: "sport-lv0"
  },
  "racecards-lv2-racing-lv1-sport-lv0": {
    id: "racecards-lv2-racing-lv1-sport-lv0",
    href: "/racing/racecards/",
    text: "Racecards",
    level: 2,
    parent: "racing-lv1-sport-lv0"
  },
  "fastresults-lv2-racing-lv1-sport-lv0": {
    id: "fastresults-lv2-racing-lv1-sport-lv0",
    href: "/racing/fast-results/",
    text: "Fast Results",
    level: 2,
    parent: "racing-lv1-sport-lv0"
  },
  "grandnational-lv2-racing-lv1-sport-lv0": {
    id: "grandnational-lv2-racing-lv1-sport-lv0",
    href: "/grand-national/",
    text: "Grand National",
    level: 2,
    parent: "racing-lv1-sport-lv0"
  },
  "cheltenhamfestival-lv2-racing-lv1-sport-lv0": {
    id: "cheltenhamfestival-lv2-racing-lv1-sport-lv0",
    href: "/cheltenham-festival/",
    text: "Cheltenham Festival",
    level: 2,
    parent: "racing-lv1-sport-lv0"
  },
  "more-lv1-sport-lv0": {
    id: "more-lv1-sport-lv0",
    href: "/sport/all/",
    text: "More...",
    level: 1,
    parent: "sport-lv0"
  },
  "economy-lv1-business-lv0": {
    id: "economy-lv1-business-lv0",
    href: "/business/economy/",
    text: "Economy",
    level: 1,
    parent: "business-lv0"
  },
  "bankofengland-lv2-economy-lv1-business-lv0": {
    id: "bankofengland-lv2-economy-lv1-business-lv0",
    href: "/bank-of-england/",
    text: "Bank of England",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "ukeconomy-lv2-economy-lv1-business-lv0": {
    id: "ukeconomy-lv2-economy-lv1-business-lv0",
    href: "/uk-economy/",
    text: "UK economy",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "globaleconomy-lv2-economy-lv1-business-lv0": {
    id: "globaleconomy-lv2-economy-lv1-business-lv0",
    href: "/global-economy/",
    text: "Global economy",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "jobs-lv2-economy-lv1-business-lv0": {
    id: "jobs-lv2-economy-lv1-business-lv0",
    href: "/jobs-and-employment/",
    text: "Jobs",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "interestrates-lv2-economy-lv1-business-lv0": {
    id: "interestrates-lv2-economy-lv1-business-lv0",
    href: "/interest-rates/",
    text: "Interest rates",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "eurozone-lv2-economy-lv1-business-lv0": {
    id: "eurozone-lv2-economy-lv1-business-lv0",
    href: "/eurozone/",
    text: "Eurozone",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "housing-lv2-economy-lv1-business-lv0": {
    id: "housing-lv2-economy-lv1-business-lv0",
    href: "/housing-market/",
    text: "Housing",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "oil-lv2-economy-lv1-business-lv0": {
    id: "oil-lv2-economy-lv1-business-lv0",
    href: "/oil/",
    text: "Oil",
    level: 2,
    parent: "economy-lv1-business-lv0"
  },
  "companies-lv1-business-lv0": {
    id: "companies-lv1-business-lv0",
    href: "/business/companies/",
    text: "Companies",
    level: 1,
    parent: "business-lv0"
  },
  "banksandfinance-lv2-companies-lv1-business-lv0": {
    id: "banksandfinance-lv2-companies-lv1-business-lv0",
    href: "/banks-and-finance/",
    text: "Banks and finance",
    level: 2,
    parent: "companies-lv1-business-lv0"
  },
  "retailandconsumer-lv2-companies-lv1-business-lv0": {
    id: "retailandconsumer-lv2-companies-lv1-business-lv0",
    href: "/retail-and-consumer-industry/",
    text: "Retail and consumer",
    level: 2,
    parent: "companies-lv1-business-lv0"
  },
  "media-lv2-companies-lv1-business-lv0": {
    id: "media-lv2-companies-lv1-business-lv0",
    href: "/media-and-telecoms/",
    text: "Media",
    level: 2,
    parent: "companies-lv1-business-lv0"
  },
  "energy-lv2-companies-lv1-business-lv0": {
    id: "energy-lv2-companies-lv1-business-lv0",
    href: "/energy-industry/",
    text: "Energy",
    level: 2,
    parent: "companies-lv1-business-lv0"
  },
  "transport-lv2-companies-lv1-business-lv0": {
    id: "transport-lv2-companies-lv1-business-lv0",
    href: "/transport-and-industry/",
    text: "Transport",
    level: 2,
    parent: "companies-lv1-business-lv0"
  },
  "property-lv2-companies-lv1-business-lv0": {
    id: "property-lv2-companies-lv1-business-lv0",
    href: "/property-and-construction-industry/",
    text: "Property",
    level: 2,
    parent: "companies-lv1-business-lv0"
  },
  "manufacturing-lv2-companies-lv1-business-lv0": {
    id: "manufacturing-lv2-companies-lv1-business-lv0",
    href: "/manufacturing-industry/",
    text: "Manufacturing",
    level: 2,
    parent: "companies-lv1-business-lv0"
  },
  "markets-lv1-business-lv0": {
    id: "markets-lv1-business-lv0",
    href: "/business/markets/",
    text: "Markets",
    level: 1,
    parent: "business-lv0"
  },
  "ftse100-lv2-markets-lv1-business-lv0": {
    id: "ftse100-lv2-markets-lv1-business-lv0",
    href: "/ftse-100/",
    text: "FTSE 100",
    level: 2,
    parent: "markets-lv1-business-lv0"
  },
  "bonds-lv2-markets-lv1-business-lv0": {
    id: "bonds-lv2-markets-lv1-business-lv0",
    href: "/bonds/",
    text: "Bonds",
    level: 2,
    parent: "markets-lv1-business-lv0"
  },
  "alex-lv1-business-lv0": {
    id: "alex-lv1-business-lv0",
    href: "/business/alex/",
    text: "Alex",
    level: 1,
    parent: "business-lv0"
  },
  "telegraphconnect-lv1-business-lv0": {
    id: "telegraphconnect-lv1-business-lv0",
    href: "/connect/small-business/",
    text: "Telegraph Connect",
    level: 1,
    parent: "business-lv0"
  },
  "talentandleadership-lv2-telegraphconnect-lv1-business-lv0": {
    id: "talentandleadership-lv2-telegraphconnect-lv1-business-lv0",
    href: "/connect/small-business/talent-culture-leadership/",
    text: "Talent and leadership",
    level: 2,
    parent: "telegraphconnect-lv1-business-lv0"
  },
  "salesandtrade-lv2-telegraphconnect-lv1-business-lv0": {
    id: "salesandtrade-lv2-telegraphconnect-lv1-business-lv0",
    href: "/connect/small-business/sales-and-trade/",
    text: "Sales and trade",
    level: 2,
    parent: "telegraphconnect-lv1-business-lv0"
  },
  "finance-lv2-telegraphconnect-lv1-business-lv0": {
    id: "finance-lv2-telegraphconnect-lv1-business-lv0",
    href: "/connect/small-business/finance-and-funding/",
    text: "Finance",
    level: 2,
    parent: "telegraphconnect-lv1-business-lv0"
  },
  "marketing-lv2-telegraphconnect-lv1-business-lv0": {
    id: "marketing-lv2-telegraphconnect-lv1-business-lv0",
    href: "/connect/small-business/marketing/",
    text: "Marketing",
    level: 2,
    parent: "telegraphconnect-lv1-business-lv0"
  },
  "investing-lv1-money-lv0": {
    id: "investing-lv1-money-lv0",
    href: "/investing/",
    text: "Investing",
    level: 1,
    parent: "money-lv0"
  },
  "shares-lv2-investing-lv1-money-lv0": {
    id: "shares-lv2-investing-lv1-money-lv0",
    href: "/investing/shares/",
    text: "Shares",
    level: 2,
    parent: "investing-lv1-money-lv0"
  },
  "funds-lv2-investing-lv1-money-lv0": {
    id: "funds-lv2-investing-lv1-money-lv0",
    href: "/investing/funds/",
    text: "Funds",
    level: 2,
    parent: "investing-lv1-money-lv0"
  },
  "buytolet-lv2-investing-lv1-money-lv0": {
    id: "buytolet-lv2-investing-lv1-money-lv0",
    href: "/investing/buy-to-let/",
    text: "Buy to let",
    level: 2,
    parent: "investing-lv1-money-lv0"
  },
  "isas-lv2-investing-lv1-money-lv0": {
    id: "isas-lv2-investing-lv1-money-lv0",
    href: "/investing/isas/",
    text: "ISAs",
    level: 2,
    parent: "investing-lv1-money-lv0"
  },
  "jisas-lv2-investing-lv1-money-lv0": {
    id: "jisas-lv2-investing-lv1-money-lv0",
    href: "/investing/jisa/",
    text: "JISAs",
    level: 2,
    parent: "investing-lv1-money-lv0"
  },
  "sipps-lv2-investing-lv1-money-lv0": {
    id: "sipps-lv2-investing-lv1-money-lv0",
    href: "/investing/sipps/",
    text: "SIPPs",
    level: 2,
    parent: "investing-lv1-money-lv0"
  },
  "specialreports-lv2-investing-lv1-money-lv0": {
    id: "specialreports-lv2-investing-lv1-money-lv0",
    href: "/money/special-reports/",
    text: "Special reports",
    level: 2,
    parent: "investing-lv1-money-lv0"
  },
  "property-lv1-money-lv0": {
    id: "property-lv1-money-lv0",
    href: "/property/",
    text: "Property",
    level: 1,
    parent: "money-lv0"
  },
  "houseprices-lv2-property-lv1-money-lv0": {
    id: "houseprices-lv2-property-lv1-money-lv0",
    href: "/property/house-prices/",
    text: "House prices",
    level: 2,
    parent: "property-lv1-money-lv0"
  },
  "buyingandselling-lv2-property-lv1-money-lv0": {
    id: "buyingandselling-lv2-property-lv1-money-lv0",
    href: "/property/buy/",
    text: "Buying and selling",
    level: 2,
    parent: "property-lv1-money-lv0"
  },
  "abroad-lv2-property-lv1-money-lv0": {
    id: "abroad-lv2-property-lv1-money-lv0",
    href: "/property/abroad/",
    text: "Abroad",
    level: 2,
    parent: "property-lv1-money-lv0"
  },
  "retirement-lv2-property-lv1-money-lv0": {
    id: "retirement-lv2-property-lv1-money-lv0",
    href: "/property/retirement/",
    text: "Retirement",
    level: 2,
    parent: "property-lv1-money-lv0"
  },
  "westcountry-lv2-property-lv1-money-lv0": {
    id: "westcountry-lv2-property-lv1-money-lv0",
    href: "/property/west-country/",
    text: "West Country",
    level: 2,
    parent: "property-lv1-money-lv0"
  },
  "luxury-lv2-property-lv1-money-lv0": {
    id: "luxury-lv2-property-lv1-money-lv0",
    href: "/property/luxury/",
    text: "Luxury",
    level: 2,
    parent: "property-lv1-money-lv0"
  },
  "propertydoctors-lv2-property-lv1-money-lv0": {
    id: "propertydoctors-lv2-property-lv1-money-lv0",
    href: "/property/property-doctors/",
    text: "Property Doctors",
    level: 2,
    parent: "property-lv1-money-lv0"
  },
  "pensions-lv1-money-lv0": {
    id: "pensions-lv1-money-lv0",
    href: "/pensions-retirement/",
    text: "Pensions",
    level: 1,
    parent: "money-lv0"
  },
  "annuities-lv2-pensions-lv1-money-lv0": {
    id: "annuities-lv2-pensions-lv1-money-lv0",
    href: "/pensions-retirement/annuities/",
    text: "Annuities",
    level: 2,
    parent: "pensions-lv1-money-lv0"
  },
  "financialplanning-lv2-pensions-lv1-money-lv0": {
    id: "financialplanning-lv2-pensions-lv1-money-lv0",
    href: "/pensions-retirement/financial-planning/",
    text: "Financial planning",
    level: 2,
    parent: "pensions-lv1-money-lv0"
  },
  "inheritancetax-lv2-pensions-lv1-money-lv0": {
    id: "inheritancetax-lv2-pensions-lv1-money-lv0",
    href: "/pensions-retirement/inheritance-tax/",
    text: "Inheritance tax",
    level: 2,
    parent: "pensions-lv1-money-lv0"
  },
  "news-lv2-pensions-lv1-money-lv0": {
    id: "news-lv2-pensions-lv1-money-lv0",
    href: "/pensions-retirement/news/",
    text: "News",
    level: 2,
    parent: "pensions-lv1-money-lv0"
  },
  "taxretirement-lv2-pensions-lv1-money-lv0": {
    id: "taxretirement-lv2-pensions-lv1-money-lv0",
    href: "/pensions-retirement/tax-retirement/",
    text: "Tax retirement",
    level: 2,
    parent: "pensions-lv1-money-lv0"
  },
  "banking-lv1-money-lv0": {
    id: "banking-lv1-money-lv0",
    href: "/personal-banking/",
    text: "Banking",
    level: 1,
    parent: "money-lv0"
  },
  "currentaccounts-lv2-banking-lv1-money-lv0": {
    id: "currentaccounts-lv2-banking-lv1-money-lv0",
    href: "/personal-banking/current-accounts/",
    text: "Current accounts",
    level: 2,
    parent: "banking-lv1-money-lv0"
  },
  "mortgages-lv2-banking-lv1-money-lv0": {
    id: "mortgages-lv2-banking-lv1-money-lv0",
    href: "/personal-banking/mortgages/",
    text: "Mortgages",
    level: 2,
    parent: "banking-lv1-money-lv0"
  },
  "equityrelease-lv2-banking-lv1-money-lv0": {
    id: "equityrelease-lv2-banking-lv1-money-lv0",
    href: "/equity-release/",
    text: "Equity release",
    level: 2,
    parent: "banking-lv1-money-lv0"
  },
  "creditcards-lv2-banking-lv1-money-lv0": {
    id: "creditcards-lv2-banking-lv1-money-lv0",
    href: "/personal-banking/credit-cards/",
    text: "Credit cards",
    level: 2,
    parent: "banking-lv1-money-lv0"
  },
  "savings-lv2-banking-lv1-money-lv0": {
    id: "savings-lv2-banking-lv1-money-lv0",
    href: "/personal-banking/savings/",
    text: "Savings",
    level: 2,
    parent: "banking-lv1-money-lv0"
  },
  "tax-lv1-money-lv0": {
    id: "tax-lv1-money-lv0",
    href: "/tax/",
    text: "Tax",
    level: 1,
    parent: "money-lv0"
  },
  "capitalgains-lv2-tax-lv1-money-lv0": {
    id: "capitalgains-lv2-tax-lv1-money-lv0",
    href: "/tax/capital-gains/",
    text: "Capital gains",
    level: 2,
    parent: "tax-lv1-money-lv0"
  },
  "incometax-lv2-tax-lv1-money-lv0": {
    id: "incometax-lv2-tax-lv1-money-lv0",
    href: "/tax/income-tax/",
    text: "Income tax",
    level: 2,
    parent: "tax-lv1-money-lv0"
  },
  "inheritancetax-lv2-tax-lv1-money-lv0": {
    id: "inheritancetax-lv2-tax-lv1-money-lv0",
    href: "/tax/inheritance/",
    text: "Inheritance tax",
    level: 2,
    parent: "tax-lv1-money-lv0"
  },
  "taxnews-lv2-tax-lv1-money-lv0": {
    id: "taxnews-lv2-tax-lv1-money-lv0",
    href: "/tax/news/",
    text: "Tax news",
    level: 2,
    parent: "tax-lv1-money-lv0"
  },
  "taxreturn-lv2-tax-lv1-money-lv0": {
    id: "taxreturn-lv2-tax-lv1-money-lv0",
    href: "/tax/return/",
    text: "Tax return",
    level: 2,
    parent: "tax-lv1-money-lv0"
  },
  "selfemployedtax-lv2-tax-lv1-money-lv0": {
    id: "selfemployedtax-lv2-tax-lv1-money-lv0",
    href: "/tax/self-employed/",
    text: "Self-employed tax",
    level: 2,
    parent: "tax-lv1-money-lv0"
  },
  "insurance-lv1-money-lv0": {
    id: "insurance-lv1-money-lv0",
    href: "/insurance/",
    text: "Insurance",
    level: 1,
    parent: "money-lv0"
  },
  "homeinsurance-lv2-insurance-lv1-money-lv0": {
    id: "homeinsurance-lv2-insurance-lv1-money-lv0",
    href: "/insurance/home/",
    text: "Home insurance",
    level: 2,
    parent: "insurance-lv1-money-lv0"
  },
  "life-lv2-insurance-lv1-money-lv0": {
    id: "life-lv2-insurance-lv1-money-lv0",
    href: "/insurance/life/",
    text: "Life",
    level: 2,
    parent: "insurance-lv1-money-lv0"
  },
  "pet-lv2-insurance-lv1-money-lv0": {
    id: "pet-lv2-insurance-lv1-money-lv0",
    href: "/insurance/pet/",
    text: "Pet",
    level: 2,
    parent: "insurance-lv1-money-lv0"
  },
  "health-lv2-insurance-lv1-money-lv0": {
    id: "health-lv2-insurance-lv1-money-lv0",
    href: "/insurance/health/",
    text: "Health",
    level: 2,
    parent: "insurance-lv1-money-lv0"
  },
  "car-lv2-insurance-lv1-money-lv0": {
    id: "car-lv2-insurance-lv1-money-lv0",
    href: "/insurance/car/",
    text: "Car",
    level: 2,
    parent: "insurance-lv1-money-lv0"
  },
  "travel-lv2-insurance-lv1-money-lv0": {
    id: "travel-lv2-insurance-lv1-money-lv0",
    href: "/insurance/travel/",
    text: "Travel",
    level: 2,
    parent: "insurance-lv1-money-lv0"
  },
  "warranties-lv2-insurance-lv1-money-lv0": {
    id: "warranties-lv2-insurance-lv1-money-lv0",
    href: "/insurance/warranty/",
    text: "Warranties",
    level: 2,
    parent: "insurance-lv1-money-lv0"
  },
  "billsandutilities-lv1-money-lv0": {
    id: "billsandutilities-lv1-money-lv0",
    href: "/bills-and-utilities/",
    text: "Bills and utilities",
    level: 1,
    parent: "money-lv0"
  },
  "gasandelectric-lv2-billsandutilities-lv1-money-lv0": {
    id: "gasandelectric-lv2-billsandutilities-lv1-money-lv0",
    href: "/bills-and-utilities/gas-electric/",
    text: "Gas and electric",
    level: 2,
    parent: "billsandutilities-lv1-money-lv0"
  },
  "broadband-lv2-billsandutilities-lv1-money-lv0": {
    id: "broadband-lv2-billsandutilities-lv1-money-lv0",
    href: "/bills-and-utilities/broadband/",
    text: "Broadband",
    level: 2,
    parent: "billsandutilities-lv1-money-lv0"
  },
  "tv-lv2-billsandutilities-lv1-money-lv0": {
    id: "tv-lv2-billsandutilities-lv1-money-lv0",
    href: "/bills-and-utilities/tv/",
    text: "TV",
    level: 2,
    parent: "billsandutilities-lv1-money-lv0"
  },
  "phone-lv2-billsandutilities-lv1-money-lv0": {
    id: "phone-lv2-billsandutilities-lv1-money-lv0",
    href: "/bills-and-utilities/phone/",
    text: "Phone",
    level: 2,
    parent: "billsandutilities-lv1-money-lv0"
  },
  "renewableenergy-lv2-billsandutilities-lv1-money-lv0": {
    id: "renewableenergy-lv2-billsandutilities-lv1-money-lv0",
    href: "/bills-and-utilities/renewable-energy/",
    text: "Renewable energy",
    level: 2,
    parent: "billsandutilities-lv1-money-lv0"
  },
  "more-lv1-money-lv0": {
    id: "more-lv1-money-lv0",
    href: "/money/all/",
    text: "More...",
    level: 1,
    parent: "money-lv0"
  },
  "telegraphview-lv1-opinion-lv0": {
    id: "telegraphview-lv1-opinion-lv0",
    href: "/opinion/telegraph-view/",
    text: "Telegraph View",
    level: 1,
    parent: "opinion-lv0"
  },
  "ourcolumnists-lv1-opinion-lv0": {
    id: "ourcolumnists-lv1-opinion-lv0",
    href: "/opinion/columnists/",
    text: "Our columnists",
    level: 1,
    parent: "opinion-lv0"
  },
  "letterstotheeditor-lv1-opinion-lv0": {
    id: "letterstotheeditor-lv1-opinion-lv0",
    href: "/opinion/letters/",
    text: "Letters to the Editor",
    level: 1,
    parent: "opinion-lv0"
  },
  "obituaries-lv1-opinion-lv0": {
    id: "obituaries-lv1-opinion-lv0",
    href: "/obituaries/",
    text: "Obituaries",
    level: 1,
    parent: "opinion-lv0"
  },
  "cartoons-lv1-opinion-lv0": {
    id: "cartoons-lv1-opinion-lv0",
    href: "/opinion/cartoons/",
    text: "Cartoons",
    level: 1,
    parent: "opinion-lv0"
  },
  "onthisday-lv1-opinion-lv0": {
    id: "onthisday-lv1-opinion-lv0",
    href: "/on-this-day/",
    text: "On this day",
    level: 1,
    parent: "opinion-lv0"
  },
  "gadgets-lv1-tech-lv0": {
    id: "gadgets-lv1-tech-lv0",
    href: "/technology/gadgets/",
    text: "Gadgets",
    level: 1,
    parent: "tech-lv0"
  },
  "mobilephones-lv2-gadgets-lv1-tech-lv0": {
    id: "mobilephones-lv2-gadgets-lv1-tech-lv0",
    href: "/mobile-phones/",
    text: "Mobile phones",
    level: 2,
    parent: "gadgets-lv1-tech-lv0"
  },
  "tablets-lv2-gadgets-lv1-tech-lv0": {
    id: "tablets-lv2-gadgets-lv1-tech-lv0",
    href: "/tablets/",
    text: "Tablets",
    level: 2,
    parent: "gadgets-lv1-tech-lv0"
  },
  "apps-lv2-gadgets-lv1-tech-lv0": {
    id: "apps-lv2-gadgets-lv1-tech-lv0",
    href: "/apps/",
    text: "Apps",
    level: 2,
    parent: "gadgets-lv1-tech-lv0"
  },
  "operatingsystems-lv2-gadgets-lv1-tech-lv0": {
    id: "operatingsystems-lv2-gadgets-lv1-tech-lv0",
    href: "/os/",
    text: "Operating systems",
    level: 2,
    parent: "gadgets-lv1-tech-lv0"
  },
  "thecloud-lv2-gadgets-lv1-tech-lv0": {
    id: "thecloud-lv2-gadgets-lv1-tech-lv0",
    href: "/the-cloud/",
    text: "The cloud",
    level: 2,
    parent: "gadgets-lv1-tech-lv0"
  },
  "innovation-lv1-tech-lv0": {
    id: "innovation-lv1-tech-lv0",
    href: "/technology/innovation/",
    text: "Innovation",
    level: 1,
    parent: "tech-lv0"
  },
  "artificialintelligence-lv2-innovation-lv1-tech-lv0": {
    id: "artificialintelligence-lv2-innovation-lv1-tech-lv0",
    href: "/artificial-intelligence/",
    text: "Artificial intelligence",
    level: 2,
    parent: "innovation-lv1-tech-lv0"
  },
  "internetofthings-lv2-innovation-lv1-tech-lv0": {
    id: "internetofthings-lv2-innovation-lv1-tech-lv0",
    href: "/internet-of-things/",
    text: "Internet of things",
    level: 2,
    parent: "innovation-lv1-tech-lv0"
  },
  "driverlesscars-lv2-innovation-lv1-tech-lv0": {
    id: "driverlesscars-lv2-innovation-lv1-tech-lv0",
    href: "/driverless-cars/",
    text: "Driverless cars",
    level: 2,
    parent: "innovation-lv1-tech-lv0"
  },
  "fintech-lv2-innovation-lv1-tech-lv0": {
    id: "fintech-lv2-innovation-lv1-tech-lv0",
    href: "/fintech/",
    text: "Fintech",
    level: 2,
    parent: "innovation-lv1-tech-lv0"
  },
  "biotech-lv2-innovation-lv1-tech-lv0": {
    id: "biotech-lv2-innovation-lv1-tech-lv0",
    href: "/biotech/",
    text: "Biotech",
    level: 2,
    parent: "innovation-lv1-tech-lv0"
  },
  "inventions-lv2-innovation-lv1-tech-lv0": {
    id: "inventions-lv2-innovation-lv1-tech-lv0",
    href: "/inventions/",
    text: "Inventions",
    level: 2,
    parent: "innovation-lv1-tech-lv0"
  },
  "patents-lv2-innovation-lv1-tech-lv0": {
    id: "patents-lv2-innovation-lv1-tech-lv0",
    href: "/patents/",
    text: "Patents",
    level: 2,
    parent: "innovation-lv1-tech-lv0"
  },
  "bigtech-lv1-tech-lv0": {
    id: "bigtech-lv1-tech-lv0",
    href: "/technology/big-tech/",
    text: "Big tech",
    level: 1,
    parent: "tech-lv0"
  },
  "facebook-lv2-bigtech-lv1-tech-lv0": {
    id: "facebook-lv2-bigtech-lv1-tech-lv0",
    href: "/facebook/",
    text: "Facebook",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "google-lv2-bigtech-lv1-tech-lv0": {
    id: "google-lv2-bigtech-lv1-tech-lv0",
    href: "/google/",
    text: "Google",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "amazon-lv2-bigtech-lv1-tech-lv0": {
    id: "amazon-lv2-bigtech-lv1-tech-lv0",
    href: "/amazon/",
    text: "Amazon",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "apple-lv2-bigtech-lv1-tech-lv0": {
    id: "apple-lv2-bigtech-lv1-tech-lv0",
    href: "/apple/",
    text: "Apple",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "microsoft-lv2-bigtech-lv1-tech-lv0": {
    id: "microsoft-lv2-bigtech-lv1-tech-lv0",
    href: "/microsoft/",
    text: "Microsoft",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "intel-lv2-bigtech-lv1-tech-lv0": {
    id: "intel-lv2-bigtech-lv1-tech-lv0",
    href: "/intel/",
    text: "Intel",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "uber-lv2-bigtech-lv1-tech-lv0": {
    id: "uber-lv2-bigtech-lv1-tech-lv0",
    href: "/uber/",
    text: "Uber",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "tesla-lv2-bigtech-lv1-tech-lv0": {
    id: "tesla-lv2-bigtech-lv1-tech-lv0",
    href: "/tesla/",
    text: "Tesla",
    level: 2,
    parent: "bigtech-lv1-tech-lv0"
  },
  "startups-lv1-tech-lv0": {
    id: "startups-lv1-tech-lv0",
    href: "/technology/start-ups/",
    text: "Start-ups",
    level: 1,
    parent: "tech-lv0"
  },
  "ukstartups-lv2-startups-lv1-tech-lv0": {
    id: "ukstartups-lv2-startups-lv1-tech-lv0",
    href: "/uk-startups/",
    text: "UK start-ups",
    level: 2,
    parent: "startups-lv1-tech-lv0"
  },
  "europeanstartups-lv2-startups-lv1-tech-lv0": {
    id: "europeanstartups-lv2-startups-lv1-tech-lv0",
    href: "/european-startups/",
    text: "European start-ups",
    level: 2,
    parent: "startups-lv1-tech-lv0"
  },
  "venturecapital-lv2-startups-lv1-tech-lv0": {
    id: "venturecapital-lv2-startups-lv1-tech-lv0",
    href: "/venture-capital/",
    text: "Venture capital",
    level: 2,
    parent: "startups-lv1-tech-lv0"
  },
  "politicsoftech-lv1-tech-lv0": {
    id: "politicsoftech-lv1-tech-lv0",
    href: "/technology/politics-of-tech/",
    text: "Politics of tech",
    level: 1,
    parent: "tech-lv0"
  },
  "socialmeda-lv2-politicsoftech-lv1-tech-lv0": {
    id: "socialmeda-lv2-politicsoftech-lv1-tech-lv0",
    href: "/social-media/",
    text: "Social meda",
    level: 2,
    parent: "politicsoftech-lv1-tech-lv0"
  },
  "cryptocurrency-lv2-politicsoftech-lv1-tech-lv0": {
    id: "cryptocurrency-lv2-politicsoftech-lv1-tech-lv0",
    href: "/cryptocurrency/",
    text: "Cryptocurrency",
    level: 2,
    parent: "politicsoftech-lv1-tech-lv0"
  },
  "cyberattacks-lv2-politicsoftech-lv1-tech-lv0": {
    id: "cyberattacks-lv2-politicsoftech-lv1-tech-lv0",
    href: "/cyber-attacks/",
    text: "Cyber attacks",
    level: 2,
    parent: "politicsoftech-lv1-tech-lv0"
  },
  "regulation-lv2-politicsoftech-lv1-tech-lv0": {
    id: "regulation-lv2-politicsoftech-lv1-tech-lv0",
    href: "/regulation/",
    text: "Regulation",
    level: 2,
    parent: "politicsoftech-lv1-tech-lv0"
  },
  "gaming-lv1-tech-lv0": {
    id: "gaming-lv1-tech-lv0",
    href: "/gaming/",
    text: "Gaming",
    level: 1,
    parent: "tech-lv0"
  },
  "reviews-lv2-gaming-lv1-tech-lv0": {
    id: "reviews-lv2-gaming-lv1-tech-lv0",
    href: "/gaming/reviews/",
    text: "Reviews",
    level: 2,
    parent: "gaming-lv1-tech-lv0"
  },
  "ps4-lv2-gaming-lv1-tech-lv0": {
    id: "ps4-lv2-gaming-lv1-tech-lv0",
    href: "/ps4/",
    text: "PS4",
    level: 2,
    parent: "gaming-lv1-tech-lv0"
  },
  "xboxone-lv2-gaming-lv1-tech-lv0": {
    id: "xboxone-lv2-gaming-lv1-tech-lv0",
    href: "/gaming/xbox/",
    text: "Xbox One",
    level: 2,
    parent: "gaming-lv1-tech-lv0"
  },
  "nintendoswitch-lv2-gaming-lv1-tech-lv0": {
    id: "nintendoswitch-lv2-gaming-lv1-tech-lv0",
    href: "/nintendo-switch/",
    text: "Nintendo Switch",
    level: 2,
    parent: "gaming-lv1-tech-lv0"
  },
  "pc-lv2-gaming-lv1-tech-lv0": {
    id: "pc-lv2-gaming-lv1-tech-lv0",
    href: "/pc/",
    text: "PC",
    level: 2,
    parent: "gaming-lv1-tech-lv0"
  },
  "esports-lv2-gaming-lv1-tech-lv0": {
    id: "esports-lv2-gaming-lv1-tech-lv0",
    href: "/gaming/Esports/",
    text: "Esports",
    level: 2,
    parent: "gaming-lv1-tech-lv0"
  },
  "galleries-lv2-gaming-lv1-tech-lv0": {
    id: "galleries-lv2-gaming-lv1-tech-lv0",
    href: "/gaming/Galleries/",
    text: "Galleries",
    level: 2,
    parent: "gaming-lv1-tech-lv0"
  },
  "family-lv1-lifestyle-lv0": {
    id: "family-lv1-lifestyle-lv0",
    href: "/family/",
    text: "Family",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "life-lv2-family-lv1-lifestyle-lv0": {
    id: "life-lv2-family-lv1-lifestyle-lv0",
    href: "/family/life/",
    text: "Life",
    level: 2,
    parent: "family-lv1-lifestyle-lv0"
  },
  "parenting-lv2-family-lv1-lifestyle-lv0": {
    id: "parenting-lv2-family-lv1-lifestyle-lv0",
    href: "/family/parenting/",
    text: "Parenting",
    level: 2,
    parent: "family-lv1-lifestyle-lv0"
  },
  "schooling-lv2-family-lv1-lifestyle-lv0": {
    id: "schooling-lv2-family-lv1-lifestyle-lv0",
    href: "/family/schooling/",
    text: "Schooling",
    level: 2,
    parent: "family-lv1-lifestyle-lv0"
  },
  "relationships-lv2-family-lv1-lifestyle-lv0": {
    id: "relationships-lv2-family-lv1-lifestyle-lv0",
    href: "/family/relationships/",
    text: "Relationships",
    level: 2,
    parent: "family-lv1-lifestyle-lv0"
  },
  "grandparents-lv2-family-lv1-lifestyle-lv0": {
    id: "grandparents-lv2-family-lv1-lifestyle-lv0",
    href: "/family/grandparents/",
    text: "Grandparents",
    level: 2,
    parent: "family-lv1-lifestyle-lv0"
  },
  "women-lv1-lifestyle-lv0": {
    id: "women-lv1-lifestyle-lv0",
    href: "/women/",
    text: "Women",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "politics-lv2-women-lv1-lifestyle-lv0": {
    id: "politics-lv2-women-lv1-lifestyle-lv0",
    href: "/women/politics/",
    text: "Politics",
    level: 2,
    parent: "women-lv1-lifestyle-lv0"
  },
  "work-lv2-women-lv1-lifestyle-lv0": {
    id: "work-lv2-women-lv1-lifestyle-lv0",
    href: "/women/work/",
    text: "Work",
    level: 2,
    parent: "women-lv1-lifestyle-lv0"
  },
  "sex-lv2-women-lv1-lifestyle-lv0": {
    id: "sex-lv2-women-lv1-lifestyle-lv0",
    href: "/women/sex/",
    text: "Sex",
    level: 2,
    parent: "women-lv1-lifestyle-lv0"
  },
  "womenmeanbusiness-lv2-women-lv1-lifestyle-lv0": {
    id: "womenmeanbusiness-lv2-women-lv1-lifestyle-lv0",
    href: "/women/business/",
    text: "Women Mean Business",
    level: 2,
    parent: "women-lv1-lifestyle-lv0"
  },
  "men-lv1-lifestyle-lv0": {
    id: "men-lv1-lifestyle-lv0",
    href: "/men/",
    text: "Men",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "thinkingman-lv2-men-lv1-lifestyle-lv0": {
    id: "thinkingman-lv2-men-lv1-lifestyle-lv0",
    href: "/men/thinking-man/",
    text: "Thinking man",
    level: 2,
    parent: "men-lv1-lifestyle-lv0"
  },
  "dressingwell-lv2-men-lv1-lifestyle-lv0": {
    id: "dressingwell-lv2-men-lv1-lifestyle-lv0",
    href: "/men/style/",
    text: "Dressing well",
    level: 2,
    parent: "men-lv1-lifestyle-lv0"
  },
  "relationships-lv2-men-lv1-lifestyle-lv0": {
    id: "relationships-lv2-men-lv1-lifestyle-lv0",
    href: "/men/relationships/",
    text: "Relationships",
    level: 2,
    parent: "men-lv1-lifestyle-lv0"
  },
  "thefilter-lv2-men-lv1-lifestyle-lv0": {
    id: "thefilter-lv2-men-lv1-lifestyle-lv0",
    href: "/men/the-filter/",
    text: "The filter",
    level: 2,
    parent: "men-lv1-lifestyle-lv0"
  },
  "fashion-lv1-lifestyle-lv0": {
    id: "fashion-lv1-lifestyle-lv0",
    href: "/fashion/",
    text: "Fashion",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "peopleandprofiles-lv2-fashion-lv1-lifestyle-lv0": {
    id: "peopleandprofiles-lv2-fashion-lv1-lifestyle-lv0",
    href: "/fashion/people/",
    text: "People and profiles",
    level: 2,
    parent: "fashion-lv1-lifestyle-lv0"
  },
  "stylesolutions-lv2-fashion-lv1-lifestyle-lv0": {
    id: "stylesolutions-lv2-fashion-lv1-lifestyle-lv0",
    href: "/fashion/style/",
    text: "Style solutions",
    level: 2,
    parent: "fashion-lv1-lifestyle-lv0"
  },
  "shopping-lv2-fashion-lv1-lifestyle-lv0": {
    id: "shopping-lv2-fashion-lv1-lifestyle-lv0",
    href: "/fashion/shopping/",
    text: "Shopping",
    level: 2,
    parent: "fashion-lv1-lifestyle-lv0"
  },
  "royals-lv2-fashion-lv1-lifestyle-lv0": {
    id: "royals-lv2-fashion-lv1-lifestyle-lv0",
    href: "/fashion/royals/",
    text: "Royals",
    level: 2,
    parent: "fashion-lv1-lifestyle-lv0"
  },
  "fooddrink-lv1-lifestyle-lv0": {
    id: "fooddrink-lv1-lifestyle-lv0",
    href: "/food-and-drink/",
    text: "Food & Drink",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "recipes-lv2-fooddrink-lv1-lifestyle-lv0": {
    id: "recipes-lv2-fooddrink-lv1-lifestyle-lv0",
    href: "/food-and-drink/recipes/",
    text: "Recipes",
    level: 2,
    parent: "fooddrink-lv1-lifestyle-lv0"
  },
  "beer-lv2-fooddrink-lv1-lifestyle-lv0": {
    id: "beer-lv2-fooddrink-lv1-lifestyle-lv0",
    href: "/food-and-drink/beer/",
    text: "Beer",
    level: 2,
    parent: "fooddrink-lv1-lifestyle-lv0"
  },
  "wine-lv2-fooddrink-lv1-lifestyle-lv0": {
    id: "wine-lv2-fooddrink-lv1-lifestyle-lv0",
    href: "/food-and-drink/wine/",
    text: "Wine",
    level: 2,
    parent: "fooddrink-lv1-lifestyle-lv0"
  },
  "restaurants-lv2-fooddrink-lv1-lifestyle-lv0": {
    id: "restaurants-lv2-fooddrink-lv1-lifestyle-lv0",
    href: "/food-and-drink/restaurants/",
    text: "Restaurants",
    level: 2,
    parent: "fooddrink-lv1-lifestyle-lv0"
  },
  "pubsandbars-lv2-fooddrink-lv1-lifestyle-lv0": {
    id: "pubsandbars-lv2-fooddrink-lv1-lifestyle-lv0",
    href: "/food-and-drink/pubs-and-bars/",
    text: "Pubs and bars",
    level: 2,
    parent: "fooddrink-lv1-lifestyle-lv0"
  },
  "healthfitness-lv1-lifestyle-lv0": {
    id: "healthfitness-lv1-lifestyle-lv0",
    href: "/health-fitness/",
    text: "Health & Fitness",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "body-lv2-healthfitness-lv1-lifestyle-lv0": {
    id: "body-lv2-healthfitness-lv1-lifestyle-lv0",
    href: "/health-fitness/body/",
    text: "Body",
    level: 2,
    parent: "healthfitness-lv1-lifestyle-lv0"
  },
  "mind-lv2-healthfitness-lv1-lifestyle-lv0": {
    id: "mind-lv2-healthfitness-lv1-lifestyle-lv0",
    href: "/health-fitness/mind/",
    text: "Mind",
    level: 2,
    parent: "healthfitness-lv1-lifestyle-lv0"
  },
  "nutrition-lv2-healthfitness-lv1-lifestyle-lv0": {
    id: "nutrition-lv2-healthfitness-lv1-lifestyle-lv0",
    href: "/health-fitness/nutrition/",
    text: "Nutrition",
    level: 2,
    parent: "healthfitness-lv1-lifestyle-lv0"
  },
  "beauty-lv1-lifestyle-lv0": {
    id: "beauty-lv1-lifestyle-lv0",
    href: "/beauty/",
    text: "Beauty",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "skin-lv2-beauty-lv1-lifestyle-lv0": {
    id: "skin-lv2-beauty-lv1-lifestyle-lv0",
    href: "/beauty/skin/",
    text: "Skin",
    level: 2,
    parent: "beauty-lv1-lifestyle-lv0"
  },
  "makeup-lv2-beauty-lv1-lifestyle-lv0": {
    id: "makeup-lv2-beauty-lv1-lifestyle-lv0",
    href: "/beauty/make-up/",
    text: "Make-up",
    level: 2,
    parent: "beauty-lv1-lifestyle-lv0"
  },
  "hair-lv2-beauty-lv1-lifestyle-lv0": {
    id: "hair-lv2-beauty-lv1-lifestyle-lv0",
    href: "/beauty/hair/",
    text: "Hair",
    level: 2,
    parent: "beauty-lv1-lifestyle-lv0"
  },
  "body-lv2-beauty-lv1-lifestyle-lv0": {
    id: "body-lv2-beauty-lv1-lifestyle-lv0",
    href: "/beauty/body/",
    text: "Body",
    level: 2,
    parent: "beauty-lv1-lifestyle-lv0"
  },
  "stella-lv1-lifestyle-lv0": {
    id: "stella-lv1-lifestyle-lv0",
    href: "/lifestyle/stella-magazine/",
    text: "Stella",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "cars-lv1-lifestyle-lv0": {
    id: "cars-lv1-lifestyle-lv0",
    href: "/cars/",
    text: "Cars",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "azcarfinder-lv2-cars-lv1-lifestyle-lv0": {
    id: "azcarfinder-lv2-cars-lv1-lifestyle-lv0",
    href: "/cars/a-z/",
    text: "A-Z car finder",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "reviews-lv2-cars-lv1-lifestyle-lv0": {
    id: "reviews-lv2-cars-lv1-lifestyle-lv0",
    href: "/cars/reviews/",
    text: "Reviews",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "advice-lv2-cars-lv1-lifestyle-lv0": {
    id: "advice-lv2-cars-lv1-lifestyle-lv0",
    href: "/cars/advice/",
    text: "Advice",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "news-lv2-cars-lv1-lifestyle-lv0": {
    id: "news-lv2-cars-lv1-lifestyle-lv0",
    href: "/cars/news/",
    text: "News",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "greatdrives-lv2-cars-lv1-lifestyle-lv0": {
    id: "greatdrives-lv2-cars-lv1-lifestyle-lv0",
    href: "/great-drives/",
    text: "Great drives",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "classiccars-lv2-cars-lv1-lifestyle-lv0": {
    id: "classiccars-lv2-cars-lv1-lifestyle-lv0",
    href: "/cars/classic/",
    text: "Classic cars",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "longtermtests-lv2-cars-lv1-lifestyle-lv0": {
    id: "longtermtests-lv2-cars-lv1-lifestyle-lv0",
    href: "/cars/long-term-tests/",
    text: "Long-term tests",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "garage-lv2-cars-lv1-lifestyle-lv0": {
    id: "garage-lv2-cars-lv1-lifestyle-lv0",
    href: "/cars/car-maintenance-tips/",
    text: "Garage",
    level: 2,
    parent: "cars-lv1-lifestyle-lv0"
  },
  "gardening-lv1-lifestyle-lv0": {
    id: "gardening-lv1-lifestyle-lv0",
    href: "/gardening/",
    text: "Gardening",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "howtogrow-lv2-gardening-lv1-lifestyle-lv0": {
    id: "howtogrow-lv2-gardening-lv1-lifestyle-lv0",
    href: "/gardening/how-to-grow/",
    text: "How to grow",
    level: 2,
    parent: "gardening-lv1-lifestyle-lv0"
  },
  "gardenstovisit-lv2-gardening-lv1-lifestyle-lv0": {
    id: "gardenstovisit-lv2-gardening-lv1-lifestyle-lv0",
    href: "/gardening/gardens-to-visit/",
    text: "Gardens to visit",
    level: 2,
    parent: "gardening-lv1-lifestyle-lv0"
  },
  "toolsandaccessories-lv2-gardening-lv1-lifestyle-lv0": {
    id: "toolsandaccessories-lv2-gardening-lv1-lifestyle-lv0",
    href: "/gardening/tools-and-accessories/",
    text: "Tools and accessories",
    level: 2,
    parent: "gardening-lv1-lifestyle-lv0"
  },
  "chelseaflowershow-lv2-gardening-lv1-lifestyle-lv0": {
    id: "chelseaflowershow-lv2-gardening-lv1-lifestyle-lv0",
    href: "/gardening/chelsea-flower-show/",
    text: "Chelsea Flower Show",
    level: 2,
    parent: "gardening-lv1-lifestyle-lv0"
  },
  "luxury-lv1-lifestyle-lv0": {
    id: "luxury-lv1-lifestyle-lv0",
    href: "/luxury/",
    text: "Luxury",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "pictures-lv2-luxury-lv1-lifestyle-lv0": {
    id: "pictures-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/pictures/",
    text: "Pictures",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "travel-lv2-luxury-lv1-lifestyle-lv0": {
    id: "travel-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/travel/",
    text: "Travel",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "drinkinganddining-lv2-luxury-lv1-lifestyle-lv0": {
    id: "drinkinganddining-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/drinking-and-dining/",
    text: "Drinking and dining",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "jewellery-lv2-luxury-lv1-lifestyle-lv0": {
    id: "jewellery-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/jewellery/",
    text: "Jewellery",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "motoring-lv2-luxury-lv1-lifestyle-lv0": {
    id: "motoring-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/motoring/",
    text: "Motoring",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "watches-lv2-luxury-lv1-lifestyle-lv0": {
    id: "watches-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/watches/",
    text: "Watches",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "womensstyle-lv2-luxury-lv1-lifestyle-lv0": {
    id: "womensstyle-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/womens-style/",
    text: "Women's style",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "mensstyle-lv2-luxury-lv1-lifestyle-lv0": {
    id: "mensstyle-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/mens-style/",
    text: "Men's style",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "design-lv2-luxury-lv1-lifestyle-lv0": {
    id: "design-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/design/",
    text: "Design",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "propertyandarchitecture-lv2-luxury-lv1-lifestyle-lv0": {
    id: "propertyandarchitecture-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/property-and-architecture/",
    text: "Property and architecture",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "art-lv2-luxury-lv1-lifestyle-lv0": {
    id: "art-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/art/",
    text: "Art",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "technology-lv2-luxury-lv1-lifestyle-lv0": {
    id: "technology-lv2-luxury-lv1-lifestyle-lv0",
    href: "/luxury/technology/",
    text: "Technology",
    level: 2,
    parent: "luxury-lv1-lifestyle-lv0"
  },
  "more-lv1-lifestyle-lv0": {
    id: "more-lv1-lifestyle-lv0",
    href: "/lifestyle/all/",
    text: "More...",
    level: 1,
    parent: "lifestyle-lv0"
  },
  "tv-lv1-culture-lv0": {
    id: "tv-lv1-culture-lv0",
    href: "/tv/",
    text: "TV",
    level: 1,
    parent: "culture-lv0"
  },
  "tvguide-lv2-tv-lv1-culture-lv0": {
    id: "tvguide-lv2-tv-lv1-culture-lv0",
    href: "/tv/tv-guide/",
    text: "TV guide",
    level: 2,
    parent: "tv-lv1-culture-lv0"
  },
  "news-lv2-tv-lv1-culture-lv0": {
    id: "news-lv2-tv-lv1-culture-lv0",
    href: "/tv/news/",
    text: "News",
    level: 2,
    parent: "tv-lv1-culture-lv0"
  },
  "reviews-lv2-tv-lv1-culture-lv0": {
    id: "reviews-lv2-tv-lv1-culture-lv0",
    href: "/tv/reviews/",
    text: "Reviews",
    level: 2,
    parent: "tv-lv1-culture-lv0"
  },
  "film-lv1-culture-lv0": {
    id: "film-lv1-culture-lv0",
    href: "/films/",
    text: "Film",
    level: 1,
    parent: "culture-lv0"
  },
  "news-lv2-film-lv1-culture-lv0": {
    id: "news-lv2-film-lv1-culture-lv0",
    href: "/films/news/",
    text: "News",
    level: 2,
    parent: "film-lv1-culture-lv0"
  },
  "reviews-lv2-film-lv1-culture-lv0": {
    id: "reviews-lv2-film-lv1-culture-lv0",
    href: "/films/reviews/",
    text: "Reviews",
    level: 2,
    parent: "film-lv1-culture-lv0"
  },
  "whattowatch-lv2-film-lv1-culture-lv0": {
    id: "whattowatch-lv2-film-lv1-culture-lv0",
    href: "/films/what-to-watch/",
    text: "What to watch",
    level: 2,
    parent: "film-lv1-culture-lv0"
  },
  "features-lv2-film-lv1-culture-lv0": {
    id: "features-lv2-film-lv1-culture-lv0",
    href: "/films/features/",
    text: "Features",
    level: 2,
    parent: "film-lv1-culture-lv0"
  },
  "interviews-lv2-film-lv1-culture-lv0": {
    id: "interviews-lv2-film-lv1-culture-lv0",
    href: "/films/interviews/",
    text: "Interviews",
    level: 2,
    parent: "film-lv1-culture-lv0"
  },
  "music-lv1-culture-lv0": {
    id: "music-lv1-culture-lv0",
    href: "/music/",
    text: "Music",
    level: 1,
    parent: "culture-lv0"
  },
  "reviews-lv2-music-lv1-culture-lv0": {
    id: "reviews-lv2-music-lv1-culture-lv0",
    href: "/music/reviews/",
    text: "Reviews",
    level: 2,
    parent: "music-lv1-culture-lv0"
  },
  "whattolistento-lv2-music-lv1-culture-lv0": {
    id: "whattolistento-lv2-music-lv1-culture-lv0",
    href: "/music/what-to-listen-to/",
    text: "What to listen to",
    level: 2,
    parent: "music-lv1-culture-lv0"
  },
  "livemusic-lv2-music-lv1-culture-lv0": {
    id: "livemusic-lv2-music-lv1-culture-lv0",
    href: "/music/concerts/",
    text: "Live music",
    level: 2,
    parent: "music-lv1-culture-lv0"
  },
  "classical-lv2-music-lv1-culture-lv0": {
    id: "classical-lv2-music-lv1-culture-lv0",
    href: "/music/classical-music/",
    text: "Classical",
    level: 2,
    parent: "music-lv1-culture-lv0"
  },
  "books-lv1-culture-lv0": {
    id: "books-lv1-culture-lv0",
    href: "/books/",
    text: "Books",
    level: 1,
    parent: "culture-lv0"
  },
  "reviews-lv2-books-lv1-culture-lv0": {
    id: "reviews-lv2-books-lv1-culture-lv0",
    href: "/books/reviews/",
    text: "Reviews",
    level: 2,
    parent: "books-lv1-culture-lv0"
  },
  "whattoread-lv2-books-lv1-culture-lv0": {
    id: "whattoread-lv2-books-lv1-culture-lv0",
    href: "/books/what-to-read/",
    text: "What to read",
    level: 2,
    parent: "books-lv1-culture-lv0"
  },
  "nonfiction-lv2-books-lv1-culture-lv0": {
    id: "nonfiction-lv2-books-lv1-culture-lv0",
    href: "/books/non-fiction/",
    text: "Non fiction",
    level: 2,
    parent: "books-lv1-culture-lv0"
  },
  "childrensbooks-lv2-books-lv1-culture-lv0": {
    id: "childrensbooks-lv2-books-lv1-culture-lv0",
    href: "/books/childrens-books/",
    text: "Children's books",
    level: 2,
    parent: "books-lv1-culture-lv0"
  },
  "hayfestival-lv2-books-lv1-culture-lv0": {
    id: "hayfestival-lv2-books-lv1-culture-lv0",
    href: "/hay-festival/",
    text: "Hay Festival",
    level: 2,
    parent: "books-lv1-culture-lv0"
  },
  "theatre-lv1-culture-lv0": {
    id: "theatre-lv1-culture-lv0",
    href: "/theatre/",
    text: "Theatre",
    level: 1,
    parent: "culture-lv0"
  },
  "reviews-lv2-theatre-lv1-culture-lv0": {
    id: "reviews-lv2-theatre-lv1-culture-lv0",
    href: "/theatre/reviews/",
    text: "Reviews",
    level: 2,
    parent: "theatre-lv1-culture-lv0"
  },
  "whattosee-lv2-theatre-lv1-culture-lv0": {
    id: "whattosee-lv2-theatre-lv1-culture-lv0",
    href: "/theatre/what-to-see/",
    text: "What to see",
    level: 2,
    parent: "theatre-lv1-culture-lv0"
  },
  "royalshakespearecompany-lv2-theatre-lv1-culture-lv0": {
    id: "royalshakespearecompany-lv2-theatre-lv1-culture-lv0",
    href: "/royal-shakespeare-company/",
    text: "Royal Shakespeare Company",
    level: 2,
    parent: "theatre-lv1-culture-lv0"
  },
  "opera-lv1-culture-lv0": {
    id: "opera-lv1-culture-lv0",
    href: "/opera/",
    text: "Opera",
    level: 1,
    parent: "culture-lv0"
  },
  "glyndebourne-lv2-opera-lv1-culture-lv0": {
    id: "glyndebourne-lv2-opera-lv1-culture-lv0",
    href: "/glyndebourne/",
    text: "Glyndebourne",
    level: 2,
    parent: "opera-lv1-culture-lv0"
  },
  "art-lv1-culture-lv0": {
    id: "art-lv1-culture-lv0",
    href: "/art/",
    text: "Art",
    level: 1,
    parent: "culture-lv0"
  },
  "hitlists-lv1-culture-lv0": {
    id: "hitlists-lv1-culture-lv0",
    href: "/culture/hit-lists/",
    text: "Hit lists",
    level: 1,
    parent: "culture-lv0"
  },
  "more-lv1-culture-lv0": {
    id: "more-lv1-culture-lv0",
    href: "/culture/all/",
    text: "More...",
    level: 1,
    parent: "culture-lv0"
  }
};

const fuse = new Fuse(Object.values(SECTIONS), {
  shouldSort: true,
  includeScore: false,
  threshold: 0.4,
  distance: 500,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ["text"]
});

export function sectionSearch(name: string): SectionInterface[] {
  return fuse.search(name);
}

export function getSectionByFuzzyName(
  name: string
): SectionInterface | undefined {
  const topResult = sectionSearch(name)[0] || false;
  return topResult || undefined;
}
